//Body
$fontFamily: "Hind Siliguri" !important;

//Header
$headerHeight: 50px;
$headerIconSize: 22px;
$headerIconMarginRight: 20px;
$rbLogoHeight: 37px;
$primaryHeaderColor: #ea3592;
$headerBGLight: white;
$headerIconColor: #465a69;

//Footer
$footerHeight: 25px;
$footerFontColor: #ffffff;

//Sidenav
$sidenavWidth: 238px;
$totalHeaderFooterHeight: 73px; //header 48px + footer 25 px
$sidenavIconSize: 20px;
$sidenavTextSize: 14px;
$sidenavItemPadding: 10px 20px;
$sidenavItemMarginBottom: 10px;
$sidenavIconMarginRight: 15px;
$sidenavActiveBGColor: #e9f7fc;
$sidenavActiveFontColor: #4EAFB3;
$sidenavBorderColor: #e2e2e2;
$sidenavBoxShadow: 5px 0px 4px -3px #e2e2e2;

//App
$appSide: 130px;
$appSideHomePage: 140px;
$appIconSize: 25px;
$appRowGridGap: 2%;
$appRowGridGapConfig: 5%;
$appColumnGridGap: 3%;

$appRowGridGapHomePage: 3%;
$appBGColor: #f9f9f9;

//Filters
$filtersPadding: 15px;
$filtersMargin: 20px 0px;
$filterItemHeight: 60px;

//Common
$primaryColor: #415a6c;
$secondaryColor: #ea3592;
$generalTextColor: #4EAFB3;
$tertiaryColor: #25b1e6;
$fourthColor: #f5c405;
$errorColor: red;
$deleteColor: red;
$primaryFontColorDark: #ffffff;
$primaryFontSizeS: 13px;
$secondaryFontSize: 12px;
$primaryFontSizeSM: 13px;
$primaryFontSizeM: 15px;
$primaryFontSizeL: 16px;
$primaryFontSizeXL: 18px;
$primaryFontSizeXXL: 20px;
$paddingAround: 30px 30px 15px 30px;
$paddingAroundSingle: 30px;
$mainWidth: calc(100% - #{$sidenavWidth});
$mainHeight: calc(100vh - #{$totalHeaderFooterHeight});
$primaryBR: 4px;
$inputEleBorderColor: #dedede;
$inputEleBlackBorderColor: #46596a;
$inputEleHeight: 35px;
$inputEleWidth: 190px;
$inputElementWidth: 225px !important;
$inputLeftSpacing: 3px;
