@import "../../styles/sass/NewThemeVariables.scss";

.schema-table-container {
	height: calc(100% - 80px);
	overflow-y: auto;
	.default {cursor: default;}
}

.dataset-schema-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: sticky;
	top: 0px;
	background-color: white;
	.drawer-cancel-icon {
		color: $secondaryColor;
		display: flex !important;
		justify-content: flex-end;
		font-size: 24px;
	}
}

.no-configs {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 2%;
	img {
		height: 20vh;
		width: 100%;
	}
	.no-data {
		color: $primaryColor;
		text-align: center;
		font-size: 14px;
	}
}

.rows-icons-style {
	margin-right: 7px;
	width: 20px !important;
	height: 20px !important;
}

.table-row {
	color: $primaryColor;
}

.config-name {
	white-space: nowrap;
	max-width: 95%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.w-75 {
	width: 75%;
}

.details-header {
	color: rgba(70, 89, 106, 1);
	font-weight: 600;
	line-height: 24px;
	border-bottom: none;
	text-transform: capitalize;
}

.subsection {
	background-color: #f5f5f5;
}

.pl-5 {
	padding-left: 5px;
}
