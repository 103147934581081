@import "../../../styles/sass/NewThemeVariables.scss";

.breadcrumb {
  margin-bottom: 15px;
  font-weight: bolder;
  .breadcrumb-item-container {
    .breadcrumb-item {
      font-size: $primaryFontSizeS;
      text-decoration: none !important;
      text-transform: capitalize;

      &.active {
        font-weight: 600;
        text-decoration: none !important;
      }
    }
  }
}

.breadcrumb.light {
  color: $sidenavActiveFontColor;
  .breadcrumb-item-container {
    .breadcrumb-item {
      color: $sidenavActiveFontColor;

      &.active {
        color: $primaryColor;
      }
    }
  }
}
