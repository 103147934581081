@import "../../styles/sass/NewThemeVariables.scss";
@import "../../styles/sass/variables.scss";

.dataset-details-container{
	height: calc(100vh - 280px);
    overflow-y: auto;
}

.dataset-score {
	min-width: 30px;
	display: flex;
	justify-content: center;
}

.rows-icons-style {
	margin-right: 7px;
	width: 20px !important;
	height: 20px !important;
}

.table-row {
	color: $primaryColor;
}

.dataset-config-name {
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 120px;
}

.dataset-location-name {
	white-space: nowrap;
	width: fit-content;
	overflow: hidden;
	text-overflow: ellipsis;
}

.config-name {
	white-space: nowrap;
	width: 160px;
	// width: 200px;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-center-align {
	display: flex;
	justify-content: center;
}

.w-75 {
	width: 75%;
}

.delete_icon {
	font-size: $iconS;
	color: $primaryColor;
}

.run-icon {
	margin-right: 7px;
	width: 20px;
	height: 20px;

	&.Running {
		width: 30px;
		height: 30px;
		background-image: url(../../assets/images/running.svg);
		background-repeat: no-repeat;
	}

	&.Queued {
		background-image: url(../../assets/images/run_normal.svg);
		background-repeat: no-repeat;
	}

	&.Success {
		background-image: url(../../assets/images/run_sucess.svg);
		background-repeat: no-repeat;
	}

	&.Failed {
		background-image: url(../../assets/images/run_fail.svg);
		background-repeat: no-repeat;
	}
}

.details-header {
	color: rgba(70, 89, 106, 1);
	font-weight: 600;
	line-height: 24px;
	border-bottom: none;
	text-transform: capitalize;
}

.subsection {
	background-color: #f5f5f5;
}

.pl-5 {
	padding-left: 5px;
}

.material-icons-outlined {
	font-size: 32px;
}

.main-heading {
	font-family: Hind Siliguri !important;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 16px;
	line-height: 26px;
	color: #465a69;
	padding-top: 0px;
}

.iconSize{
	font-size: 24px !important;
}