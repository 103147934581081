@import "../../../styles/sass/NewThemeVariables.scss";

.dialog-popup-btn {
  display: flex;
  justify-content: flex-end;
  margin: 7px 15px;
  border-top: 1px solid $inputEleBorderColor;
  padding: 10px 0px;
}
.dialog-popup-title {
  font-size: 15px;
  font-family: "Hind Siliguri";
  max-width: 450px !important;
  margin: 20px;
  color: $primaryColor;
  min-width: 225px;
}

aside {
  // width: $sidenavWidth;
  z-index: 1;

  .menu-div {
    display: flex;
    align-items: center;
    padding: 10px 20px 5px 20px;
    cursor: pointer;
    .nav-text-menu {
      margin-left: 12px;
      color: $primaryColor;
    }
  }

  .covid-faq-div {
    margin: 5px 10px;
    display: flex;
    align-items: center;
    padding: $sidenavItemPadding;
    cursor: pointer;
    color: $primaryColor;
    position: fixed;
    bottom: 30px;

    .nav-icon {
      width: $sidenavIconSize;
      font-size: $sidenavIconSize;
      margin-right: $sidenavIconMarginRight;
    }

    .nav-text {
      margin: 0px;
      display: flex;
      font-size: $sidenavTextSize;
      justify-content: left;
      align-items: center;
    }
  }

  nav {
    padding-top: $paddingAroundSingle;
    .nav-item {
      display: flex;
      align-items: center;
      padding: $sidenavItemPadding;
      cursor: pointer;

      &.special {
        border-radius: 4px;
        font-weight: 600;
        &.active {
          display: flex;
          align-items: center;
          margin: none;
          justify-content: flex-start;
          width: inherit;
          padding: $sidenavItemPadding;
          border-radius: 2px;
        }
      }

      .nav-icon {
        width: $sidenavIconSize;
        font-size: $sidenavIconSize;
        margin-right: $sidenavIconMarginRight;
      }

      .nav-text {
        margin: 0px;
        display: flex;
        font-size: $sidenavTextSize;
        justify-content: left;
        align-items: center;
        transition: font-size $transition;

        &.hidden {
          font-size: 0px;
          opacity: 0;
        }

        &.visible {
          font-size: 13px;
          opacity: 1;
        }
      }
    }

    .nav-item-menu {
      display: flex;
      align-items: center;
      padding: $sidenavItemPadding;
      margin: 5px 10px;
      cursor: pointer;
      .nav-icon-menu {
        width: $sidenavIconSize;
        font-size: $sidenavIconSize;
        margin-right: $sidenavIconMarginRight;
        color: $primaryColor;
      }
      .nav-text-menu {
        display: flex;
        justify-content: left;
        align-items: center;
        //font-weight: 700;
        font-size: 15px;
        height: 25px;
        margin: 0px;
        color: $primaryColor;
        transition: all $transition;

        &.hidden {
          font-size: 0px;
          opacity: 0;
        }

        &.visible {
          font-size: 13px;
          opacity: 1;
        }
      }
    }
  }
}

aside.light.sidenav-expanded {
  width: $sidenavWidthExpanded;
}

aside.light.sidenav-collapsed {
  width: $sidenavWidthCollapsed;
}

aside.light {
  box-shadow: $sidenavBoxShadow;
  // background-color: $sidenavBGColorLight;
  transition: width $transition;

  .covid-faq-div {
    .nav-icon {
      width: $sidenavIconSize;
      font-size: $sidenavIconSize;
      margin-right: $sidenavIconMarginRight;
      color: $primaryColor;
    }

    .nav-text {
      margin: 0px;
      display: flex;
      font-size: $sidenavTextSize;
      justify-content: left;
      align-items: center;
    }
  }

  nav {
    // border-bottom: 1px solid $sidenavBorderColor;

    .nav-item {
      color: $primaryColor;
      margin: 5px 10px;

      &.special {
        background-color: $sidenavActiveFontColor;
        color: white;
        margin: 5px 10px;

        &:hover {
          background: rgba(78,175,179, 0.1);
          color: $sidenavActiveFontColor;
        }

        &.active {
          &:hover {
            background: rgba(78,175,179, 0.1);
            color: $sidenavActiveFontColor;
          }
        }
      }

      &.active,
      &:hover {
        color: $sidenavActiveFontColor;
        background-color: rgba(78,175,179, 0.1);
        border-radius: 5px;
      }
    }
  }
}

.activereport-label {
  color: $primaryColor;
  margin: 0px;
  padding-left: 20px;
  margin-bottom: 18px;
  font-weight: 700;
  position: sticky;
  font-size: 14px;
  top: 52px;
  background-color: white;
}

.no-comments {
  color: $primaryColor;
  margin: 0px;
  padding-left: 20px;
  margin-bottom: 18px;
  font-weight: 700;
  position: sticky;
  font-size: 14px;
  top: 52px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.faq-text-cross-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  background-color: white;
  .drawer-cancel-icon {
    color: $secondaryColor;
    display: flex !important;
    justify-content: flex-end;
    padding: 5px 20px 0px !important;
  }

  .faq-text {
    color: $primaryColor;
    font-weight: 700;
    padding: 0px 37px;
    text-transform: capitalize;
    font-size: 16px;
    padding-left: 20px;
    margin-bottom: 10px;
  }
}

.list-item {
  padding: 0px 20px;
  color: $primaryColor;
  margin-bottom: 15px;
  .commented_by_text {
    margin: 0px;
    font-size: 15px;
    font-weight: 900;
  }
  .list-row-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .list-primary-text {
      font-size: 14px;
      font-weight: 700;
      margin-top: 5px;
    }
    .download-icon {
      color: $primaryColor;
      margin-right: 5px;
      font-size: 17px !important;
      line-height: 0 !important;
    }
  }

  .list-secondary-text {
    margin-top: 5px;
    font-size: 13px;
  }

  .comment-secondary-text {
    margin: 0px;
    font-size: 13px;
    width: 90%;
    color: #677a89;
  }

  .comments-info {
    padding-left: 42px;
  }

  .msg-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.8em;
    margin-top: 8px;
    .sub-msg-info {
      // margin-top: 12px;
      display: flex;
      font-weight: 900;
      align-items: center;
      justify-content: space-between;
      .comment-text {
        padding-right: 15px;
        display: flex;
        p {
          padding-right: 30px;
        }
      }
      .comment-action {
        display: flex;
        justify-content: flex-end;
      }
    }
    .fav-icon {
      color: $secondaryColor;
      font-size: 20px;
      padding-left: 20px;
    }
    .delete-icon {
      color: $primaryColor;
      font-size: 20px;
      padding-left: 20px;
    }
  }
}
