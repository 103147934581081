@import "../../styles/sass/NewThemeVariables.scss";
@import "../../styles/sass/variables.scss";

.no-configs {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 2%;
    img {
      height: 20vh;
      width: 100%;
    }
    .no-data {
      color: $primaryColor;
      text-align: center;
      font-size: 14px;
    }
}
.standard-dataset-register-container{
  display: flex;
  justify-content: flex-end;
}

.register-button{
  width: 100px !important;
  background-color: #465A69 !important;
  font-family: "Hind Siliguri"!important;
}