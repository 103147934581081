.rulesSection {
  display: flex;
  align-items: end;
  position: relative;
  .rules {
    position: absolute;
    left: -200%;
    bottom: 50%;
    display: none;
    background-color: rgb(235, 238, 238);
    border-radius: 5px;
    padding: 5px 20px;
    width: 200px;
    box-shadow: 0 5px 5px rgb(235, 238, 238);
    border: 1px solid gray;
  }
  span:hover ~ .rules {
    display: block;
  }
}
