:root {
  --colour1: #465a69;
  --colour2: rgba(70, 90, 105, 0.35);
  --speed: 1s;
}

@keyframes slide {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 113px;
  }
}

.upload-loader {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .bar {
    min-height: 10px;
    border-radius: 10px;
    width: calc(113px * 4);
    @media only screen and (max-width: 450px) {
      width: calc(113px * 3);
    }
    @media only screen and (max-width: 350px) {
      width: calc(113px * 2);
    }
    box-shadow: 0px 10px 13px -6px rgba(44, 62, 80, 1);
    background-color: var(--colour2);
    background-image: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 10px,
      var(--colour1) 10px,
      var(--colour1) 20px
    );

    animation: slide var(--speed) linear infinite;
    will-change: background-position;
  }
}
