@import "../../styles/sass/NewThemeVariables.scss";

.report-btn {
	color: #4eafb3 !important;
	border: 1px solid #4eafb3;
	background: unset;
	font-size: 12px;
	height: 24px;
	padding: 0px 10px;
	font-family: $fontFamily;

	&:hover {
		background: #4eafb3 !important;
		color: #ffffff !important;
	}
}

.disabled-btn{
	color: #848b91;
	border: 1px solid #848b91;
	background: unset;
	font-size: 12px;
	height: 24px;
	padding: 0px 10px;
	font-family: $fontFamily;
	pointer-events: none;
	cursor: default;
}

.view-report-btn{
	color: #ffffff;
	border: 1px solid #4eafb3;
	background: #4eafb3;
	font-size: 12px;
	height: 24px;
	padding: 0px 10px;
	font-family: $fontFamily;

	&:hover {
		background: #4eafb3;
		color: #ffffff;
	}
}
