@import "../../../styles/sass/NewThemeVariables.scss";

.more-vert-icon-style {
  color: $primaryColor;
  font-size: 16px !important;
  &:hover {
    color: $primaryColor !important;
  }
}

.menu-item {
  color: $primaryColor;
}
.popup-btn {
  display: flex;
  justify-content: flex-end;
  margin: 7px 15px;
  border-top: 1px solid $inputEleBorderColor;
  padding: 10px 0px;
}
.popup-title {
  font-size: 15px;
  font-family: "Hind Siliguri";
  width: 225px;
  margin: 20px;
  color: #46596a;
}
