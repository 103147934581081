/** @format */
@import "../../styles/sass/NewThemeVariables.scss";

.info-text {
	margin: 0px;
}

.input-small-width {
	width: 100%;
	max-width: 100% !important;
}

.input-full-width {
	width: 100%;
	max-width: 100% !important;
}

.scheduler-detils-first-row {
	display: flex;
	flex-direction: row;
	.scheduler-sub-detil-row {
		flex-direction: column;
		width: 100%;
	}
}
.pl{
    padding-left: 10px;
}

.scheduler-button {
	font-family: Hind Siliguri !important;
	font-size: 13px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 24px !important;
	letter-spacing: 0em !important;
	text-align: left !important;
	color: #ffffff !important;
	background-color: #4eafb3 !important;
	border-radius: 5px !important;
	height: 32px !important;
	// width: 115px ;
	text-transform: none !important;
	margin-left: 10px !important;
}
.cancle-button {
	font-family: Hind Siliguri !important;
	font-size: 13px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 24px !important;
	letter-spacing: 0em !important;
	text-align: left !important;
	background: #ffffff !important;
	border: 1px solid #4eafb3 !important;
	color: #4eafb3 !important;
	box-sizing: border-box !important;
	border-radius: 5px !important;
	height: 32px !important;
	width: 70px !important;
	text-transform: none !important;
}
.icon-container {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	height: 50px;
}
.notificationIcon {
	width: 15px;
	height: 12.5px;
	top: 1.67px;
	left: 2.5px;
	margin-right: 105px;
}

.personIcon {
	width: 6.67;
	height: 6.67;
	top: 2.5px;
	left: 6.67px;
	margin-right: 17px;
}
.middle-line-brack {
	background: #f6f6f6;
	width: 100%;
	height: 4px;
	transform: matrix(1, 0, 0, -1, 0, 0);
}
.sub-heading {
	font-size: 14px !important;
}
.sub-heading-secondary {
	font-size: 13px !important;
	margin-left: 10px !important;
}
.sub-heading-data {
	margin-bottom: 10px;
}
.sub-heading-sub {
	margin-bottom: 10px;
	font-size: 14px !important;
}
.subsection-name-field-ds {
	background: #f3f3f3;
	border-radius: 5px;
	border: none;
	font-size: 12px !important;
	padding: 6px;
	color: #a5aab1;
	font-family: $fontFamily;
}
.subsection-name-field {
	width: 306px;
	height: 24px;
	background: #f3f3f3;
	border: none;
	padding-left: 2px;
	// padding-left: 12px;
	color: $primaryColor;
	font-family: $fontFamily;
}
.MuiChip-deleteIcon {
	font-size: 15.5px !important;
	width: 15px !important;
	height: 15px !important;
}
.validation-message {
	font-family: Hind Siliguri !important;
	font-size: 12px !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: 26px !important;
	letter-spacing: 0em !important;
	text-align: left !important;
	color: #465a69 !important;
}
.MuiMenuItem-root-cls {
	font-family: Hind Siliguri !important;
	font-size: 16px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 26px !important;
	letter-spacing: 0em !important;
	text-align: left !important;
	color: #b1b1b1 !important;
}
.MuiAutocomplete-option {
	font-family: Hind Siliguri;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: 23px;
	letter-spacing: 0em;
	text-align: left;
}
.subsection-name-field::placeholder {
	width: 140px;
	height: 23px;
	font-family: $fontFamily;
	font-style: normal;
	font-weight: 500;
	font-size: 13px;
	color: $primaryColor;
	opacity: 0.5;
}
.selection {
	width: 338px;
	height: 51px;
	margin-left: 20px;
	background: #ededed;
	border-radius: 5px;
	margin-top: 7px;
}
.filter-option {
	width: 140px;
	height: 23px;
	font-family: Hind Siliguri;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 23px;
	color: #465a69;
}
.button {
	width: 120px;
	height: 40px;
	position: absolute;
	right: 65px;
	bottom: 53px;
	border-radius: 5px;
	color: #ffffff;
	font-family: "Hind Siliguri";
	font-style: "normal";
	font-size: "12px";
	font-weight: 600;
	line-height: "19px";
	color: "#FFFFFF";
	background: "#4EAFB3";
	border: none;
	text-transform: none;
}
.footer {
	position: absolute;
	width: 100%;
	height: 20px;
	background: #465a69;
	bottom: 0;
}
.footer-content {
	position: absolute;
	width: 100%;
	margin: 0;
	font-family: Hind Siliguri;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 16px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
}
#display {
	display: none;
}
/** @format */

.container {
	background: rgba(196, 196, 196, 0.03);
	border: 1px solid rgba(103, 103, 103, 0.15);
	box-sizing: border-box;
	border-radius: 5px;
	margin-top: 10px;
	padding-bottom: 10pt;
}
.selected-term {
	font-family: Hind Siliguri;
	font-style: normal;
	font-weight: bolder;
	font-size: 14px;
	margin-left: 20px;
	text-transform: capitalize;
	color: #465a69;
}
.delete {
	font-family: Hind Siliguri;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 23px;
	text-transform: capitalize;
	color: #465a69;
}
.input {
	background: #f8f8f9;
	border: 1px solid rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	border-radius: 5px;
	width: 250px;
	height: 40px;
	margin-left: 20px;
	margin-right: 30px;
}
.chips {
	background: #465a69;
	border-radius: 3px;
	width: 131px;
	height: 42px;
}
.chips p {
	font-family: Hind Siliguri;
	font-style: normal;
	font-weight: 500;
	font-size: 13.5px;
	line-height: 22px;
	color: #ffffff;
}
/** @format */

.main-container {
	margin: 25px 25px 25px 28px;
}
.scheduler-main-container {
	margin: 10px;
}
.main-heading {
	font-family: Hind Siliguri !important;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 16px;
	line-height: 26px;
	color: #465a69;
	padding-top: 0px;
}
.first-container {
	display: grid;
	grid-template-columns: 45% 45% 10%;
	margin-top: 20px;
}
.imageIcon {
	height: "100%";
}

.ratify-selections {
	margin-top: 10px;
}

.ratify-data-container {
	height: calc(100% - 48px);
	.top-panel {
		height: calc(100% - 30px);
		overflow-y: auto;
		margin: 0px -30px 0px 0px;
		padding: 0px 30px 0px 0px;
		width: inherit;
	}
	.bottom-panel {
		height: 30px;
		margin: 0px -10px 0px -30px;
	}
}

